import { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import {
  getXXLSessionCookie,
  getXXLCookie,
  getEventStreamSessionCookie,
} from "../../utils/Cookie";
import type {
  RecommendationsRequest,
  RecommendationsResponseResponse,
  RecommendationsApi,
} from "@xxl/recommendations-api";
import { legacySiteUidToSiteUid } from "../../utils/xxl-shared-data";
import isEmpty from "lodash/isEmpty";
import type { Translate } from "../../contexts/Translations/TranslationsContext";
import type { ProductRecommendationsProps } from ".";
import { hexToRGB } from "../../utils/xxl-hex-to-rgb";
import { xxlTheme } from "../../styles/xxl-theme";
import type { EcomSiteUidLegacy } from "../../global";
import { logError } from "../../utils/xxl-log";
import type { ColorTheme } from "@xxl/content-api";
import { isNotNullOrUndefined } from "@xxl/common-utils";

export enum ListStyles {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export type FixedCardData = {
  heading: string;
  url: string;
  backgroundColor?: string;
  imageUrl?: string;
  imageAlt?: string;
  textColor?: string;
};

export type Recommendation = {
  listStyle: ListStyles;
  productsCount: number;
  recommendationsKey: string;
  strategy: RecommendationsParametersStrategyEnum;
  brandNames?: string[];
  buttonUrl?: string;
  campaignIds?: string[];
  categoryCodes?: string[];
  categoryId?: string;
  days?: number;
  element?: HTMLDivElement;
  fixedCardData?: FixedCardData;
  includedProducts?: string[];
  linkDisplayName?: string;
  linkUrl?: string;
  price?: number; // Used for minimum price in upsale strategy
  productStyleId?: string; // Used for the upsale strategy
  title?: string;
};

export type PersonalizedData = RecommendationsResponseResponse[];

const INT_BASE = 10;
export const PRODUCTS_COUNT_DEFAULT_VALUE = 12;
const DAYS_DEFAULT_VALUE = 7;

export const getListStyle = (listStyleString = ""): ListStyles => {
  const matchingStyleKey = Object.keys(ListStyles).find(
    (style) => style === listStyleString
  );
  if (matchingStyleKey === undefined) {
    return ListStyles.HORIZONTAL;
  }
  return ListStyles[matchingStyleKey as keyof typeof ListStyles];
};

export const getStrategy = (
  strategyString?: string
): RecommendationsParametersStrategyEnum => {
  const matchingStrategyKey = Object.keys(
    RecommendationsParametersStrategyEnum
  ).find((style) => style === strategyString);
  if (matchingStrategyKey === undefined) {
    return RecommendationsParametersStrategyEnum.personalized;
  }
  return RecommendationsParametersStrategyEnum[
    matchingStrategyKey as keyof typeof RecommendationsParametersStrategyEnum
  ];
};

export const getProductsCount = (productsCountString?: string): number => {
  return productsCountString !== undefined && productsCountString.length > 0
    ? parseInt(productsCountString, INT_BASE)
    : PRODUCTS_COUNT_DEFAULT_VALUE;
};

const getDays = (daysString?: string): number => {
  const days =
    daysString !== undefined
      ? parseInt(daysString, INT_BASE)
      : DAYS_DEFAULT_VALUE;
  return days !== 0 ? days : DAYS_DEFAULT_VALUE;
};

const getStringArrayFromString = (string?: string): string[] | undefined => {
  if (string !== undefined && string.length !== 0 && string !== "[]") {
    return string
      .replace(/\[/g, "")
      .replace(/]/g, "")
      .replace(/, /g, ",")
      .split(",");
  }
  return undefined;
};

const getProductId = (code: string): string => {
  return code.split("_").shift() ?? "";
};

export const getIncludedProducts = (
  strategy: string,
  productIdsString?: string,
  cartEntries?: string[]
): string[] => {
  if (strategy === RecommendationsParametersStrategyEnum.recently) {
    const xxlSessionCookie = getXXLSessionCookie();
    cartEntries = cartEntries?.map((val) => getProductId(val));
    return xxlSessionCookie.recentlyViewedProducts !== undefined
      ? xxlSessionCookie.recentlyViewedProducts.filter((val) =>
          cartEntries !== undefined
            ? !cartEntries.includes(getProductId(val))
            : true
        )
      : [];
  }
  return getStringArrayFromString(productIdsString) ?? [];
};

export const getImageUrlAndAltAttr = (
  backgroundColor?: string,
  fixedCardImageUrl?: string,
  fixedCardBackupImageUrl?: string
): string | undefined => {
  if (fixedCardImageUrl !== undefined && fixedCardImageUrl !== "") {
    return fixedCardImageUrl;
  } else if (fixedCardImageUrl === "" && backgroundColor !== "") {
    return undefined;
  } else {
    return fixedCardBackupImageUrl;
  }
};

export const getOptionalFixedCardData = (
  dataset: DOMStringMap
): FixedCardData | undefined => {
  const {
    fixedCardHeading,
    campaignId,
    fixedCardImageUrl,
    fixedCardImageUrlFallback,
    fixedCardColor: backgroundColor,
    fixedCardTextColor: textColor,
  } = dataset;
  const heading = fixedCardHeading;
  if (
    campaignId !== undefined &&
    campaignId !== "" &&
    heading !== undefined &&
    heading !== ""
  ) {
    const imageUrl = getImageUrlAndAltAttr(
      backgroundColor,
      fixedCardImageUrl,
      fixedCardImageUrlFallback
    );

    return {
      heading,
      url: campaignId,
      backgroundColor,
      imageUrl,
      textColor,
    };
  }
  return;
};

const getPrice = (price: string | undefined): number | undefined => {
  if (price !== "" && price !== undefined) {
    return Number(price);
  }
  return undefined;
};

export const getTitle = (
  strategy: RecommendationsParametersStrategyEnum,
  t: Translate
): string | undefined => {
  switch (strategy) {
    case RecommendationsParametersStrategyEnum.upsale:
      return t("carousel.upsale.title");
    case RecommendationsParametersStrategyEnum.recently:
      return t("carousel.recently.viewed.title");
    case RecommendationsParametersStrategyEnum.personalized:
      return t("carousel.personalized.title");
    case RecommendationsParametersStrategyEnum.frequently_bought_together:
      return t("carousel.frequently.bought.together.title");
    default:
      return;
  }
};

export const getProductRecommendationsFromElement = (
  element: HTMLDivElement,
  t: Translate
): Recommendation => {
  const {
    categoryId,
    listStyle: listStyleString,
    productsCount: productsCountString,
    recommendationsKey,
    title: titleString,
    strategy: strategyString,
    days: daysString,
    styleId: productStyleId,
    price: priceString,
    brandNames: brandNamesString,
    categoryCodes: categoryCodesString,
    campaignIds: campaignIdsString,
    productIds: productIdsString,
    cartEntries: cartEntriesString,
    buttonUrl,
    linkDisplayName,
    linkUrl,
  } = element.dataset;
  const price = getPrice(priceString);
  const strategy = getStrategy(strategyString);
  const productsCount = getProductsCount(productsCountString);
  const listStyle = getListStyle(listStyleString);
  const days = getDays(daysString);
  const cartEntries = getStringArrayFromString(cartEntriesString);
  const brandNames = getStringArrayFromString(brandNamesString);
  const categoryCodes = getStringArrayFromString(categoryCodesString);
  const campaignIds = getStringArrayFromString(campaignIdsString);
  const includedProducts = getIncludedProducts(
    strategy,
    productIdsString,
    cartEntries
  );
  const fixedCardData = getOptionalFixedCardData(element.dataset);

  const title =
    titleString === "" || titleString === undefined
      ? getTitle(strategy, t)
      : titleString;

  if (recommendationsKey === undefined || recommendationsKey === "") {
    throw new Error(
      `Missing recommendationsKey for "${title ?? "unknown"}" module`
    );
  }

  return {
    categoryId,
    element,
    recommendationsKey,
    listStyle,
    productsCount,
    title,
    strategy,
    productStyleId,
    price,
    days,
    includedProducts,
    brandNames,
    categoryCodes,
    campaignIds,
    buttonUrl,
    fixedCardData,
    linkDisplayName,
    linkUrl,
  };
};

export const hideRecommendations = (
  recommendations: Recommendation[]
): void => {
  recommendations.forEach(({ element }) => {
    if (element !== undefined) {
      element.style.display = "none";
    }
  });
};

const hideRecommendation = (recommendation: Recommendation): void => {
  if (recommendation.element !== undefined) {
    recommendation.element.style.display = "none";
  }
};

export const fetchData =
  (
    recommendations: Recommendation[],
    setPersonalizedData: (data: PersonalizedData) => void,
    recommendationsApi: RecommendationsApi,
    siteUid: EcomSiteUidLegacy
  ) =>
  async (): Promise<void> => {
    try {
      const dataSetId = legacySiteUidToSiteUid(siteUid);
      const cookie = await getXXLCookie();
      const sessionId = getEventStreamSessionCookie() ?? undefined;
      const recommendationsRequest: RecommendationsRequest =
        recommendations.map((recommendation) => {
          if (
            recommendation.brandNames === undefined &&
            recommendation.campaignIds === undefined &&
            recommendation.categoryCodes === undefined &&
            !isEmpty(recommendation.categoryId)
          ) {
            recommendation.categoryCodes = [recommendation.categoryId ?? ""];
          }
          return {
            numResults: recommendation.productsCount,
            key: recommendation.recommendationsKey,
            strategy: recommendation.strategy,
            sessionId: sessionId,
            userGroupIds: cookie?.userGroups,
            price: recommendation.price,
            styleId: recommendation.productStyleId,
            includedProducts: recommendation.includedProducts,
            filters: {
              days: recommendation.days,
              brands: recommendation.brandNames,
              campaigns: recommendation.campaignIds,
              categories: recommendation.categoryCodes,
            },
            linkDisplayName: recommendation.linkDisplayName,
            linkUrl: recommendation.linkUrl,
          };
        });
      const data = (
        await recommendationsApi.getRecommendations(
          dataSetId,
          recommendationsRequest
        )
      ).data;

      recommendations.map((recommendation) => {
        const products = data.response.find(
          ({ key }) => key === recommendation.recommendationsKey
        )?.productList;
        if (isEmpty(products)) {
          hideRecommendation(recommendation);
        }
      });

      setPersonalizedData(data.response);
    } catch (error) {
      logError(error);
      hideRecommendations(recommendations);
    }
  };

export const createRecomendationsProps = (
  recommendations: Recommendation[],
  personalizedData: PersonalizedData
): React.SetStateAction<ProductRecommendationsProps[]> =>
  recommendations.map((recommendation) => {
    const { recommendationsKey } = recommendation;
    const products = personalizedData.find(
      ({ key }) => key === recommendationsKey
    )?.productList;
    return {
      products,
      ...recommendation,
      carouselType: recommendation.strategy,
    };
  });

const { colors } = xxlTheme;

const defaultColorTheme = {
  backgroundColor: colors.xxlWhite,
  textColor: colors.xxlBlack,
};
const strategyColorThemes = [
  {
    strategy: RecommendationsParametersStrategyEnum.upsale,
    backgroundColor: colors.xxlLightBlue,
    textColor: colors.xxlBlue,
  },
  {
    strategy: RecommendationsParametersStrategyEnum.recently,
    backgroundColor: colors.xxlLightOrange,
    textColor: colors.xxlDarkOrange,
  },
  {
    strategy: RecommendationsParametersStrategyEnum.frequently_bought_together,
    backgroundColor: colors.xxlLightAmber,
    textColor: colors.xxlDarkAmber,
  },
  {
    strategy: RecommendationsParametersStrategyEnum.personalized,
    backgroundColor: colors.xxlLightTurquoise,
    textColor: colors.xxlDarkTurquoise,
  },
  {
    strategy: RecommendationsParametersStrategyEnum.popularity,
    backgroundColor: colors.xxlLightGreen,
    textColor: colors.xxlDarkGreen,
  },
  {
    strategy: RecommendationsParametersStrategyEnum.bestseller,
    backgroundColor: colors.xxlLightGreen,
    textColor: colors.xxlDarkGreen,
  },
];

const getCarouselColorTheme = (
  strategy?: RecommendationsParametersStrategyEnum
) =>
  strategyColorThemes.find((item) => item.strategy === strategy) ??
  defaultColorTheme;

const getColorThemeCss = (backgroundColor: string, textColor: string) => `
  background-color: ${hexToRGB(backgroundColor, 0.4)};
  color: ${textColor};
`;

export const productCarouselColorTheme = (
  type: RecommendationsParametersStrategyEnum,
  colorTheme?: ColorTheme
) => {
  if (isNotNullOrUndefined(colorTheme)) {
    return getColorThemeCss(colorTheme.value, colorTheme.font);
  }
  const theme = getCarouselColorTheme(type);

  return getColorThemeCss(theme.backgroundColor, theme.textColor);
};
