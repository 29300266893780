import { isNotNull, isNullOrUndefined } from "@xxl/common-utils";
import type { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import isEmpty from "lodash/isEmpty";
import * as React from "react";
import { InView } from "react-intersection-observer";
import { useSharedData } from "../../contexts/SharedData";
import { useTracking, type Trackers } from "../../contexts/Tracking";
import type { Translate } from "../../contexts/Translations/TranslationsContext";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { ProductMetaData } from "../../global";
import { useXxlMediaQuery } from "../../hooks/useXxlMediaQuery";
import { logError } from "../../utils/xxl-log";
import { SkeletonWrapper } from "../Common";
import { CaretIcon } from "../Common/Icons/Caret";
import { Slider } from "../Common/Slider";
import { PriceWithLabels } from "../Product/PriceWithLabels/PriceWithLabels";
import { Product } from "../Product/Product";
import {
  getPriceData,
  getProductType,
  toProductCardData,
  type CombinedProductData,
} from "../Product/product-helper";
import {
  cardHeightMobile,
  nonHoverableCardHeight,
} from "../Product/Product.styled";
import type { Recommendation } from "./personalized-product-list-helper";
import { ListStyles } from "./personalized-product-list-helper";
import {
  Header,
  HeaderText,
  Link,
  LinkDisplayName,
  PersonalizedWrapper,
  SeeAllProductsButton,
} from "./ProductList.styled";
import { trackProductImpression } from "./tracking-helper";
import { DEFAULT_GRID_VALUE } from "../Product/constants";

export const TITLE_TEST_ID = "personalized-product-title";
const SLIDER_TEST_ID = "personalised-product-slider";
export const LINK_TEXT_TEST_ID = "link-text";
export const LINK_URL_TEST_ID = "link-url";

export type ProductRecommendationsProps = Recommendation & {
  products?: CombinedProductData[];
  productsDisplayDesktop?: number;
  productsDisplayTablet?: number;
  productsDisplayMobile?: number;
  carouselType: RecommendationsParametersStrategyEnum;
  showLoadingStatus?: boolean;
};

type HandleOnChangeProps = {
  listName: string;
  position: number;
  product?: CombinedProductData;
  trackers: Trackers;
};
const handleOnChange =
  ({ listName, position, product, trackers }: HandleOnChangeProps) =>
  (inView: boolean) => {
    if (inView && product !== undefined) {
      trackProductImpression({
        listName,
        position,
        product: {
          brandName: product.brand?.name,
          code: product.code,
          googleCategory:
            "googleCategory" in product ? product.googleCategory : undefined,
          name: product.name,
          style: product.style,
          salesPrice: product.priceDisplay?.salesPrice,
        },
        trackers,
      });
    }
  };

const shouldPrioImage = (
  args:
    | {
        prioritizeImage: true;
        isLaptop: boolean;
        positionInList: number;
      }
    | {
        prioritizeImage: false;
      }
) => {
  if (!args.prioritizeImage) {
    return false;
  }

  const { isLaptop, positionInList } = args;
  const nrOfImagesToPrefetch = isLaptop ? 6 : 2;
  return positionInList < nrOfImagesToPrefetch;
};

type CommonCreateProductItemArgs = {
  listName: string;
  listStyle: ListStyles;
  productsCount: number;
  pageType: string;
  trackers: Trackers;
  siteDefaultLanguage: string;
  toggleProductsAsPackageQuantity: boolean;
  t: Translate;
  products?: CombinedProductData[];
};

type CreateProductItemArgs =
  | (CommonCreateProductItemArgs & {
      prioritizeImage: true;
      isLaptop: boolean;
    })
  | (CommonCreateProductItemArgs & {
      prioritizeImage: false;
    });

export const createProductItems = (props: CreateProductItemArgs) => {
  const {
    listName,
    listStyle,
    productsCount,
    pageType,
    trackers,
    siteDefaultLanguage,
    toggleProductsAsPackageQuantity,
    t,
    products,
  } = props;
  const isHorizontal = listStyle === ListStyles.HORIZONTAL;
  const tagName = isHorizontal ? "div" : "li";
  const arrayToMap = products ?? Array(productsCount).fill(undefined);

  return arrayToMap.map((product: CombinedProductData | undefined, index) => {
    if (isNullOrUndefined(product)) {
      return null;
    }

    const productType = getProductType(product);
    const units = "units" in product ? product.units : undefined;
    const { code, priceDisplay } = product;

    if (isNullOrUndefined(priceDisplay)) {
      logError(
        `Product with code ${code ?? "undefined"} is miising price display.`
      );
      return null;
    }

    const position = index + 1;
    const productMetaData: ProductMetaData = {
      pageType,
      position,
      list: "carousel",
    };

    const priceData = getPriceData({
      version: 1,
      priceDisplay,
      productType,
      showPackagePrice: false,
      siteDefaultLanguage,
      toggleProductsAsPackageQuantity,
      units,
    });

    if (priceData === null) {
      logError(
        `Product with code ${code ?? "undefined"} is missing price data.`
      );
      return null;
    }

    const { colorTheme, highlightedLabel, priceSplash } = priceData;

    return (
      <InView
        as={tagName}
        key={position}
        threshold={0.5}
        onChange={handleOnChange({
          listName,
          position,
          product,
          trackers,
        })}
        triggerOnce={true}
      >
        <Product
          colorTheme={colorTheme}
          highlightedLabel={highlightedLabel}
          isSliderProductList={true}
          selectedColumnsNumber={DEFAULT_GRID_VALUE}
          personalizedProductListName={listName}
          PriceComponent={
            <PriceWithLabels
              version={1}
              priceDisplay={priceDisplay}
              productType={productType}
              selectedColumnsNumber={DEFAULT_GRID_VALUE}
              t={t}
            />
          }
          priceSplash={priceSplash}
          prioritizeImageLoad={shouldPrioImage(
            props.prioritizeImage
              ? {
                  prioritizeImage: true,
                  isLaptop: props.isLaptop,
                  positionInList: index,
                }
              : {
                  prioritizeImage: false,
                }
          )}
          product={toProductCardData(
            product,
            "productType" in product ? product.productType : "NORMAL"
          )}
          productMetaData={productMetaData}
          selectedFilters={[]}
          showFavoritesHeart={true}
          showHighlightedLabel={true}
        />
      </InView>
    );
  });
};

export const ProductList: React.FunctionComponent<
  ProductRecommendationsProps
> = ({
  listStyle,
  products,
  productsCount,
  title,
  buttonUrl,
  linkDisplayName,
  linkUrl,
  carouselType,
  showLoadingStatus = false,
  ...props
}) => {
  const { t } = useTranslations();
  const {
    featureToggles: { toggle_products_as_package_quantity },
    siteDefaultLanguage,
    pageType,
  } = useSharedData().data;
  const trackers = useTracking();
  const listName = `${pageType}_${props.strategy}`;
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const getProductCount = () => {
    if (isMobile) {
      return 2;
    }

    if (isLaptop) {
      return 6;
    }

    return 3;
  };

  const productItems = createProductItems({
    prioritizeImage: false,
    listName,
    listStyle,
    productsCount,
    pageType,
    trackers,
    siteDefaultLanguage,
    toggleProductsAsPackageQuantity: toggle_products_as_package_quantity,
    t,
    products,
  }).filter(isNotNull);
  const cardHeight = isLaptop ? nonHoverableCardHeight : cardHeightMobile;

  return (
    <PersonalizedWrapper carouselType={carouselType}>
      <Header data-testid={TITLE_TEST_ID}>
        {Boolean(title) && (
          <HeaderText className="line-clamp line-clamp--two-rows">
            {title}
          </HeaderText>
        )}
        {Boolean(linkDisplayName) &&
          linkUrl !== undefined &&
          linkUrl !== "" &&
          listStyle === ListStyles.HORIZONTAL && (
            <Link
              href={linkUrl}
              data-testid={LINK_URL_TEST_ID}
              carouselType={carouselType}
            >
              <LinkDisplayName data-testid={LINK_TEXT_TEST_ID}>
                {linkDisplayName}
              </LinkDisplayName>
              <CaretIcon direction="right" />
            </Link>
          )}
      </Header>

      <SkeletonWrapper
        style={{ minHeight: cardHeight }}
        isLoading={showLoadingStatus && products?.length === 0}
      >
        {listStyle === ListStyles.HORIZONTAL ? (
          <Slider
            testId={SLIDER_TEST_ID}
            items={productItems}
            navigation={isLaptop ? "ARROWS" : "DOTS"}
            slidesConfig={{
              perView: getProductCount(),
              spacing: 8,
            }}
          />
        ) : (
          <ul className="product-list product-list--multiline product-list--4-col-max">
            {productItems}
          </ul>
        )}
        {!isEmpty(buttonUrl) ? (
          <SeeAllProductsButton
            href={buttonUrl}
            className="button button--primary button--small"
          >
            {t("campaign.show.all.button")}
          </SeeAllProductsButton>
        ) : null}
      </SkeletonWrapper>
    </PersonalizedWrapper>
  );
};
